import React from 'react'
import './app.css'
import Navbar from './Components/Navbar/Navbar' 
import Home from './Components/Home/Home'

const App = () => {
    return (
        <>
        <Navbar/>
        <Home/>
       {/*  <Home/>
        <Main/>
        <Footer/> */}
        </>
    )
}

export default App
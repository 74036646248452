import React from 'react'
import './home.css'
import video from '../../Assets/video.mp4'
import img from '../../Assets/storia.jpeg'

const Home = () => {
  return (
   <section className="home">
    <div className="overlay"></div>
      {/* <video src={video} muted autoPlay loop typeof='video/mp4'></video> */}
      <img src={img} alt="Storia" />
   </section> 
  )
}

export default Home